<script setup>
import { defineProps, defineEmits, ref } from "vue"
import { Icon } from "@iconify/vue"
import Button from "primevue/button"

defineProps({
  type: String,
  classs: String,
  classConfirm: String,
  icon: String,
  iconConfirm: String,
  iconClass: String,
  label: String,
  labelConfirm: String,
  labelClass: String
})

const emits = defineEmits(["confirm"])

const showConfirmButton = ref(false)
const showInplaceConfirmButton = () => {
  showConfirmButton.value = true
  setTimeout(() => {
    showConfirmButton.value = false
  }, 2000)
}
</script>

<template>
  <Button v-if="!showConfirmButton" type="button" @click="showInplaceConfirmButton" :class="classs">
    <Icon :icon="icon" :class="iconClass" />
    <span :class="labelClass">{{ label }}</span>
  </Button>
  <Button v-else :type="type" @click="emits('confirm')" :class="classConfirm">
    <Icon :icon="iconConfirm" :class="iconClass" />
    <span :class="labelClass">{{ labelConfirm }}</span>
  </Button>
</template>
