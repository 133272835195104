<template>
  <!-- Loading services -->
  <AutoMapperSkeleton v-if="loading" />
  <div v-else class="w-full">
    <!-- Title and button -->
    <div v-if="servicesAgregate.length" class="form-group">
      <Dropdown v-model="serviceSelected" :options="servicesAgregate" optionLabel="name" dataKey="id"
                @change="addService()" :placeholder="$t('integrations.automapper.addServices')"
                class="p-inputtext-sm shadow-sm" />
    </div>

    <!-- List services -->
    <div v-for="(service, indexService) in getServices" :key="`${service.id}_${indexService}`"
         class="bg-[#F1F5F9] p-3 flex flex-col justify-between items-center rounded-md mb-2.5">
      <div class="w-full flex flex-row justify-between items-center">
        <div class="flex flex-row justify-start items-center font-semibold text-gray-600 relative">
          <!-- Alert if the service has an error -->
          <div v-if="service.err"
               class="bg-red-500 text-white text-sm font-light -ml-5 px-2 py-0 rounded-md absolute shadow"
               v-tooltip.top="$t('integrations.automapper.tooltipErrorFrom')">!
          </div>

          <!-- Service icon -->
          <Icon
            v-if="service.name == 'Customers' && !(service.integration && service.integration.config_to?.unsubscribe)"
            icon="tabler:users" class="w-6 h-6 ml-2 text-gray-400" />
          <Icon
            v-if="service.name == 'Contacts' && !(service.integration && service.integration.config_to?.unsubscribe)"
            icon="tabler:users" class="w-6 h-6 ml-2 text-gray-400" />
          <Icon v-if="service.name == 'Orders' && !(service.integration && service.integration.config_to?.unsubscribe)"
                icon="tabler:report-money" class="w-6 h-6 ml-2 text-gray-400" />
          <Icon
            v-if="service.name == 'Newsletter' && !(service.integration && service.integration.config_to?.unsubscribe)"
            icon="tabler:mail" class="w-6 h-6 ml-2 text-gray-400" />
          <Icon
            v-if="service.name == 'Products' && !(service.integration && service.integration.config_to?.unsubscribe)"
            icon="tabler:list-details" class="w-6 h-6 ml-2 text-gray-400" />
          <Icon v-if="service.name == 'Carts' && !(service.integration && service.integration.config_to?.unsubscribe)"
                icon="tabler:shopping-cart" class="w-6 h-6 ml-2 text-gray-400" />
          <Icon v-if="service.name == 'Tickets' && !(service.integration && service.integration.config_to?.unsubscribe)"
                icon="tabler:ticket" class="w-6 h-6 ml-2 text-gray-400" />
          <Icon v-if="service.integration && service.integration.config_to?.unsubscribe" icon="tabler:user-minus"
                class="w-6 h-6 ml-2 text-gray-400" />

          <!-- Service name -->
          <p v-if="!service.integration || !service.integration.config_to?.unsubscribe"
             class="text-gray-600 text-sm font-semibold ml-3">{{ service.name }}</p>
          <p v-else class="text-gray-600 text-sm font-semibold ml-3">Unsubscribe</p>
        </div>
        <div class="flex-center space-x-3">
          <Button v-if="getWebhookLink(service)" @click="copyWebhookLink(service)" type="button"
                  class="p-button-secondary">
            <Icon icon="tabler:copy" class="w-5 h-5 mr-1" />
            <span>{{ service.copied ? $t("copied!") : $t("copyLink") }}</span>
          </Button>

          <!-- Test a few days this line -->
          <!-- <Button @click="service.openOptions = !service.openOptions" :disabled="service.err" type="button" class="p-button-secondary" v-tooltip.top="$t('integrations.automapper.editService')"> -->
          <Button @click="service.openOptions = !service.openOptions" type="button" class="p-button-secondary"
                  v-tooltip.top="$t('integrations.automapper.editService')">
            <Icon icon="tabler:settings" class="w-5 h-5" />
          </Button>
        </div>
      </div>

      <!-- List service config -->
      <div v-if="service.openOptions" class="w-full pt-3 mt-2 border-t">
        <!-- Service Active/Inactive -->
        <div v-if="service.integration_id" class="form-group">
          <div class="flex-center justify-between">
            <div class="text-gray-600 text-sm font-normal">{{ $t("integrations.automapper.tooltipDisableService") }}
              <LabelRequired />
            </div>
            <InputSwitch v-model="service.enable" />
          </div>
        </div>

        <!-- Historic date -->
        <div v-if="!service.integration_id && service.config.canSetHistoric" class="form-group">
          <div class="flex-center justify-between">
            <div class="text-gray-600 text-sm font-normal">{{ $t("integrations.automapper.sync") }}
              <LabelRequired />
            </div>
            <Dropdown v-model="service.subtractDate" :options="subtractDates" optionLabel="label"
                      optionValue="subtractNumber" dataKey="subtractNumber" :placeholder="$t('select')"
                      class="p-inputtext-sm shadow-sm w-60" />
          </div>
        </div>

        <!-- Frequency -->
        <div v-if="service.config.canSetFrequency" class="form-group">
          <div class="flex-center justify-between">
            <div class="text-gray-600 text-sm font-normal">{{ $t("integrations.automapper.frequency") }}
              <LabelRequired />
            </div>
            <Dropdown v-if="frequencyOptions(service.name).length" v-model="service.schedule_frequency"
                      :options="frequencyOptions(service.name)" optionLabel="text" optionValue="id" dataKey="id"
                      :placeholder="$t('select')" class="p-inputtext-sm shadow-sm w-60" />
          </div>
        </div>

        <!-- Frequency -->
        <div v-if="service.config.canSetSubTime" class="form-group">
          <div class="flex-center justify-between">
            <div class="text-gray-600 text-sm font-normal">{{ $t("integrations.automapper.subTime") }}</div>
            <div class="w-60">
              <InputNumber v-model="service.subTime_get_data" class="p-inputtext-sm shadow-sm" />
            </div>
          </div>
        </div>

        <!-- Custom fields 1 -->
        <div v-for="(input, i) in service.inputs" :key="i">
          <div class="form-group">
            <div class="flex-center justify-between">
              <div class="text-gray-600 text-sm font-normal">{{ $t(`wizard.inputsCustom.${input.name}`) }}
                <LabelRequired v-if="input.required == true" />
              </div>
              <div class="ml-auto w-60">
                <InputSwitch v-if="['checkbox'].includes(input.type)" v-model="input.value" :name="input.name"
                             class="float-right" />
                <InputNumber v-if="['number'].includes(input.type)" v-model="input.value" :name="input.name"
                             class="p-inputtext-sm shadow-sm" />
                <InputText v-if="['text'].includes(input.type)" v-model="input.value" :name="input.name"
                           class="p-inputtext-sm shadow-sm" />
                <Skeleton v-if="input.loading" width="100%" height="2.5rem" />
                <div class="flex justify-between w-full"
                     v-if="!input.loading && ['select', 'multiselect'].includes(input.type)">
                  <Dropdown v-if="input.type == 'select'" v-model="input.value" :options="input.options"
                            :optionLabel="input.label" :optionValue="input.keyValue" :dataKey="input.keyValue"
                            :placeholder="$t('select')" class="p-inputtext-sm shadow-sm w-48"
                            :showClear="!input.required" />
                  <MultiSelect v-if="input.type == 'multiselect'" v-model="input.value" :options="input.options"
                               :optionLabel="input.label" :optionValue="input.keyValue"
                               :disabled="!input.options.length" :filter="true"
                               :placeholder="$t(`wizard.inputsCustom.${input.name}`)"
                               class="p-inputtext-sm shadow-sm w-48" :showClear="true" />
                  <Button v-if="input.buttonReload" @click="reloadOptions('inputs', i, indexService)" type="button"
                          class="p-button-sm p-button-light text-center w-min ml-2">
                    <Icon icon="tabler:reload" class="w-6 h-6" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Custom fields 2 -->
        <div v-for="(input, i) in service.inputsTo" :key="i">
          <div class="form-group">
            <div class="flex-center justify-between">
              <div class="text-gray-600 text-sm font-normal">{{ $t(`wizard.inputsCustom.${input.name}`) }}
                <LabelRequired v-if="input.required == true" />
              </div>
              <div class="ml-auto w-60">
                <InputSwitch v-if="['checkbox'].includes(input.type)" v-model="input.value" :name="input.name"
                             class="float-right" />
                <InputNumber v-if="['number'].includes(input.type)" v-model="input.value" :name="input.name"
                             class="p-inputtext-sm shadow-sm" />
                <InputText v-if="['text'].includes(input.type)" v-model="input.value" :name="input.name"
                           class="p-inputtext-sm shadow-sm" />
                <Skeleton v-if="input.loading" width="100%" height="2.5rem" />
                <div class="flex justify-between"
                     v-if="!input.loading && ['select', 'multiselect'].includes(input.type)">
                  <Dropdown v-model="input.value" :options="input.options" :optionLabel="input.label"
                            :optionValue="input.keyValue" :dataKey="input.keyValue" :placeholder="$t('select')"
                            class="p-inputtext-sm shadow-sm w-full" />
                  <Button v-if="input.buttonReload" @click="reloadOptions('inputsTo', i, indexService)" type="button"
                          class="p-button-sm p-button-light flex-center ml-2">
                    <Icon icon="tabler:reload" class="w-6 h-6" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Fields -->
        <div v-if="!service.is_premium && service.config.canRelateFields" class="form-group">
          <div class="form-group">
            <div class="flex-center justify-between">
              <div class="text-gray-600 text-sm font-normal">{{ $t("integrations.automapper.relateFields") }}</div>
              <Button @click="showRelateFields(service, indexService)" type="button"
                      class="p-button-sm p-button-light flex-center space-x-2 ml-auto"
                      :disabled="service.inputs.filter((f) => f.required == true && f.reloadFields == true).some((f) => f.value == null || f.value == '')">
                <Icon icon="tabler:columns" class="w-6 h-6" />
                <span class="font-semibold">{{ $t("integrations.automapper.buttonConfig") }}</span>
              </Button>
            </div>
          </div>
        </div>

        <!-- Filter -->
        <div v-if="$route.query?.filter" class="form-group">
          <div class="flex-center justify-between">
            <div class="text-gray-600 text-sm font-normal">{{ $t("integrations.automapper.filter") }}</div>
            <Button @click="showModalFilter(service, indexService)" type="button"
                    class="p-button-sm p-button-light flex-center space-x-2 ml-auto">
              <Icon icon="tabler:filter" class="w-6 h-6" />
              <span class="font-semibold">{{ $t("integrations.automapper.buttonConfig") }}</span>
            </Button>
          </div>
        </div>

        <!-- Delete -->
        <div class="form-group last">
          <div class="flex-center">
            <ButtonConfirm type="button" classs="p-button-sm p-button-danger flex-center space-x-2 ml-auto"
                           classConfirm="p-button-sm p-button-danger flex-center space-x-2 ml-auto" icon="tabler:trash"
                           iconConfirm="tabler:trash" iconClass="w-6 h-6" :label="$t('mapperFields.deleteServices')"
                           :labelConfirm="$t('mapperFields.confirmDeleteServices')" labelClass="font-semibold"
                           @confirm="removeService(service, indexService)" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Dialog for map fields -->
  <Dialog :header="$t('integrations.automapper.relateFieldDialogTitle') + `${modal.name}`"
          v-model:visible="modal.openRelatedFields" modal :dismissableMask="true"
          :style="{ width: '60rem', padding: 0 }">
    <div>
      <div v-if="modal.refreshFields" class="w-full space-y-3">
        <Skeleton width="100%" height="2.5rem" />
        <Skeleton width="100%" height="2.5rem" />
        <Skeleton width="100%" height="2.5rem" />
        <Skeleton width="100%" height="2.5rem" />
        <Skeleton width="100%" height="2.5rem" />
      </div>
      <div v-else-if="!modal.refreshFields && modal.error">
        <Message severity="error" :closable="false" icon="pi pi-info-circle">
          {{ $t("integrations.automapper.notVerifyService") }} {{ modal.service?.name }}
        </Message>
      </div>
      <div v-else>
        <!-- Automapper -->
        <div class="form-group">
          <table class="table-auto">
            <thead>
            <tr>
              <td width="31%">
                <div class="flex-center space-x-2">
                  <img v-if="channelFrom" :src="channelFrom.avatar_short" class="w-14 h-14 object-contain"
                       v-tooltip.top="channelFrom.name" />
                  <div class="flex-1">
                    <span class="form-label-inline">{{ $t("integrations.automapper.fieldsFrom") }}</span>
                    <span class="form-label-inline">({{ $t("required") }})</span>
                  </div>
                </div>
              </td>
              <td width="4%"></td>
              <td width="31%">
                <div class="flex-center space-x-2">
                  <img v-if="channelTo" :src="channelTo.avatar_short" class="w-14 h-14 object-contain"
                       v-tooltip.top="channelTo.name" />
                  <div class="flex-1">
                    <span class="form-label-inline">{{ $t("integrations.automapper.fieldsTo") }}</span>
                    <span class="form-label-inline">({{ $t("required") }})</span>
                  </div>
                </div>
              </td>
              <td width="31%">
                <span class="form-label-inline">{{ $t("integrations.automapper.transformingData") }}</span>
                <span class="form-label-inline">({{ $t("optional") }})</span>
              </td>
              <td width="3%"></td>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(automapper, index) in modal.automappers" :key="index">
              <td>
                <!-- From field -->
                <div class="mr-3">
                  <Dropdown v-model="automapper.field_from" :options="modal.fieldsFrom" optionLabel="label"
                            optionValue="key" dataKey="key" :filter="true" :autoFilterFocus="true"
                            :editable="modal?.service?.config?.editableFields || false" :placeholder="$t('select')"
                            class="p-inputtext-sm shadow-sm w-full p-dropdown-automapper" />
                </div>
              </td>
              <td>
                <!-- Icon -->
                <div class="mr-3">
                  <Icon icon="tabler:arrow-big-right-lines" class="w-6 h-6" />
                </div>
              </td>
              <td>
                <!-- To field -->
                <div class="mr-3">
                  <Dropdown v-model="automapper.field_to" :options="modal.fieldsTo" optionLabel="label"
                            optionValue="key" dataKey="key" :filter="true" :autoFilterFocus="true"
                            :editable="serviceToData?.config?.editableFields || false" :placeholder="$t('select')"
                            class="p-inputtext-sm shadow-sm w-full p-dropdown-automapper" />
                </div>
              </td>
              <td>
                <!-- Function field -->
                <div class="mr-3">
                  <div class="p-inputgroup">
                      <span class="p-inputgroup-addon">
                        <Icon icon="carbon:chemistry" class="w-4 h-4" />
                      </span>
                    <InputText v-model="automapper.function" name="function" class="p-inputtext-sm shadow-sm" />
                  </div>
                </div>
              </td>
              <td>
                <!-- Remove field -->
                <Button @click="removeFieldRelated(index)" type="button" class="p-button-sm p-button-danger"
                        v-tooltip.top="$t('integrations.automapper.removeField')">
                  <Icon icon="tabler:trash" class="w-6 h-6" />
                </Button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="flex-center justify-start space-x-2">
          <!-- Example fields -->
          <Button v-if="!modal.error" type="button" @click="exampleFields" class="p-button-light flex-center space-x-2"
                  :loading="modal.example.form.busy">
            <Icon v-if="modal.example.form.busy" icon="tabler:loader-2" class="w-6 h-6 text-gray-700 animate-spin" />
            <Icon v-else icon="tabler:search" class="w-6 h-6 text-gray-700" />
            <span>{{ $t("integrations.automapper.Examples") }}</span>
          </Button>
          <Button v-else type="button" class="p-button-light flex-center space-x-2" v-tooltip="$t('integrations.automapper.tooltipErrorFrom')" disabled>
            <Icon icon="tabler:search" class="w-6 h-6 text-gray-700" />
            <span>{{ $t("integrations.automapper.Examples") }}</span>
          </Button>
          <!-- Refresh fields -->
          <Button type="button" @click="refreshFields" class="p-button-light flex-center space-x-2">
            <Icon icon="tabler:refresh" class="w-6 h-6 text-gray-700" />
            <span>{{ $t("integrations.automapper.refreshField") }}</span>
          </Button>
          <!-- Add field -->
          <Button type="button"
                  @click="modal.automappers.push({ field_from: null, field_to: null, function: null, activeFunction: false })"
                  class="p-button-light flex-center space-x-2">
            <Icon icon="tabler:circle-plus" class="w-6 h-6 text-gray-700" />
            <span>{{ $t("integrations.automapper.addField") }}</span>
          </Button>
        </div>
      </div>
    </div>
    <template #footer>
      <Button type="button" @click="modal.openRelatedFields = false" :label="$t('integrations.automapper.cancelButton')"
              class="p-button-secondary p-button-text" />
      <Button type="button" @click="saveRelateFields" :label="$t('integrations.automapper.saveButton')"
              class="p-button-primary shadow-md" />
    </template>
  </Dialog>

  <!-- Dialog for example fields -->
  <Dialog :header="$t('integrations.automapper.exampleDialogTitle') + `${modal.name}`"
          v-model:visible="modal.example.show" modal :dismissableMask="true" :style="{ width: '60rem', padding: 0 }">
    <div class="flex flex-col space-y-3">
      <Accordion v-for="(exampleRaw, exampleRawId) of modal.example.data" :key="exampleRawId">
        <AccordionTab :header="$t('mapperFields.example') + ` ${exampleRawId}`">
          <pre>{{ exampleRaw.raw }}</pre>
        </AccordionTab>
      </Accordion>
    </div>
    <template #footer>
      <Button type="button" @click="modal.example.show = false" :label="$t('integrations.automapper.cancelButton')"
              class="p-button-secondary p-button-text" />
    </template>
  </Dialog>

  <!-- Dialog for config filters -->
  <Dialog :header="$t('integrations.automapper.configFilter')" v-model:visible="modalFilter.show" modal
          :dismissableMask="true" :style="{ width: '36rem', padding: 0 }">
    <Textarea v-model="modalFilter.filter" rows="6" cols="30" name="comment" id="comment"
              class="p-inputtext-sm shadow-sm" />
    <template #footer>
      <Button type="button" @click="modalFilter.show = false" :label="$t('integrations.automapper.cancelButton')"
              class="p-button-secondary p-button-text" />
      <Button type="button" @click="saveFilter" :label="$t('integrations.automapper.saveButton')"
              class="p-button-primary shadow-md" />
    </template>
  </Dialog>
</template>

<script>
import { Icon } from "@iconify/vue"
import axiosClient from "@/config/AxiosClient"
import Dropdown from "primevue/dropdown"
import Dialog from "primevue/dialog"
import Textarea from "primevue/textarea"
import InputSwitch from "primevue/inputswitch"
import InputText from "primevue/inputtext"
import InputNumber from "primevue/inputnumber"
import Button from "primevue/button"
import Skeleton from "primevue/skeleton"
import Message from "primevue/message"
import Accordion from "primevue/accordion"
import AccordionTab from "primevue/accordiontab"
import AutoMapperSkeleton from "@/components/Integrations/Wizard/AutoMapperSkeleton"
import LabelRequired from "@/components/LabelRequired"
import ButtonConfirm from "@/components/ButtonConfirm"
import ApiRoute from "@/config/ApiRoute"
import FormClient from "@/config/FormClient"
import MultiSelect from "primevue/multiselect"

export default {
  name: "AutoMapper",
  emits: ["deleteIntegration", "dataFinish"],

  components: {
    Icon,
    Dropdown,
    Dialog,
    Textarea,
    InputSwitch,
    InputText,
    InputNumber,
    Button,
    Skeleton,
    Message,
    Accordion,
    AccordionTab,
    AutoMapperSkeleton,
    LabelRequired,
    ButtonConfirm,
    MultiSelect
  },

  props: {
    projectCredentialFromId: Number,
    projectCredentialToId: Number,
    channelFrom: {
      type: Object,
      default: null
    },
    servicesFrom: Array,
    channelTo: {
      type: Object,
      default: null
    },
    serviceTo: Object,
    integrations: {
      type: Array,
      default: function() {
        return []
      }
    },
    iframe: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      serviceSelected: null,
      servicesFromInit: [],
      servicesShow: [],
      servicesAgregate: [],
      serviceToData: {},
      loading: true,
      frequencies: [],
      subtractDates: [
        {
          label: this.$t("integrations.automapper.today"),
          subtractNumber: 0
        },
        {
          label: this.$t("integrations.automapper.1month"),
          subtractNumber: 1
        },
        {
          label: this.$t("integrations.automapper.3months"),
          subtractNumber: 3
        },
        {
          label: this.$t("integrations.automapper.6months"),
          subtractNumber: 6
        },
        {
          label: this.$t("integrations.automapper.12months"),
          subtractNumber: 12
        }
      ],
      modal: {
        automappers: [{ field_from: null, field_to: null, function: null, activeFunction: false }],
        fieldsFrom: [],
        fieldsTo: [],
        openRelatedFields: false,
        indexService: null,
        name: "",
        refreshFields: false,
        service: {},
        error: false,
        example: {
          form: new FormClient({
            project_credential_id: null,
            service_id: null,
            automapper: [],
            config: {}
          }),
          data: [],
          show: false
        }
      },
      modalFilter: {
        show: false,
        indexService: false,
        service: {},
        filter: "",
        error: false
      },
      configSearchFieldsFrom: {}
    }
  },

  computed: {
    getServices() {
      let services = this.servicesShow
      services.sort(function(a, b) {
        return a.name.localeCompare(b.name)
      })
      return services
    }
  },

  async created() {
    this.servicesFromInit = JSON.parse(JSON.stringify(this.servicesFrom))

    await this.getFrequencies()

    if (this.integrations.length) {
      let services = []
      for (const integration of this.integrations) {
        services.push({
          ...this.servicesFromInit.find((f) => f.id == integration.service_id),
          integration: integration,
          integration_id: integration.id
        })
      }
      let missingServices = this.servicesFromInit.filter((f) => !services.map((f) => f.id).includes(f.id))
      this.servicesFromInit = services.concat(missingServices)
    }

    await this.verifyServices()

    if (this.integrations.length) {
      let services = JSON.parse(JSON.stringify(this.servicesShow))
      for (const [index, service] of services.entries()) {
        if (service.integration_id) {
          let integration = this.integrations.find((i) => i.id == service.integration_id)
          services[index].schedule_frequency = integration.schedule_frequency
          services[index].automapper = integration.automapper
          services[index].inputs.map((input) => {
            if (integration.config_from && integration.config_from[input.name] !== undefined) {
              input.value = integration.config_from[input.name]
            }else if(!input.required){
              input.value = input.type == 'checkbox' ? false : null
            }

            return input
          })

          if (services[index].inputsTo) {
            services[index].inputsTo.map((input) => {
              if (integration.config_to != null && integration.config_to[input.name]) {
                input.value = integration.config_to[input.name]
              }

              return input
            })
          }

          services[index].init = integration.init
          if (integration.config_from && integration.config_from?.filter) {
            services[index].filter = JSON.stringify(integration.config_from.filter)
          }

          services[index].is_premium = integration.is_premium
          services[index].enable = integration.is_active
          if (integration.is_premium) {
            services[index].inputsTo = []
          }
        }
      }
      this.servicesShow = JSON.parse(JSON.stringify(services))
    }
    this.loading = false
  },

  methods: {
    frequencyOptions(serviceName) {
      if (!this.frequencies.length) {
        return []
      }

      return this.frequencies.filter((frequency) => {
        if (["yearly"].indexOf(frequency.id) > -1) {
          return false
        }

        if (["Newsletter", "Contacts"].indexOf(serviceName) > -1) {
          return true
        }

        if (["Newsletter", "Contacts"].indexOf(serviceName) == -1 && ["everyTenMinutes", "everyFifteenMinutes", "everyThirtyMinutes"].indexOf(frequency.id) > -1) {
          return false
        }

        return true
      })
    },

    async verifyServices() {
      let fieldsTo = !this.serviceTo.fields || !this.serviceTo.fields.length ? await this.searchFieldsTo(this.serviceTo) : this.serviceTo.fields
      let serviceToData = { ...this.serviceTo, fields: fieldsTo }
      let inputs = serviceToData.inputs
      for (const [index, input] of inputs.entries()) {
        if (["select", "multiselect"].includes(input.type) && !input.options) {
          let functionOptions = input?.function ?? input.name
          let res = await axiosClient.post(`${process.env.VUE_APP_URL_API}/api/2.0/channel/${this.serviceTo.channel_id}/external/data/${functionOptions}`, {
            project_credential_id: this.projectCredentialToId
          })
          inputs[index].options = res.data
          inputs[index].buttonReload = true
        }
        if (input.required == undefined) inputs[index].required = true
        if (input.value == undefined) inputs[index].value = null
        inputs[index].loading = false
      }
      serviceToData.inputs = inputs
      this.serviceToData = serviceToData

      let servicesFrom = this.servicesFromInit.filter((service) => service.config["isActive"] && (this.serviceToData.channel_id != 7 || service.config?.canPremium === true || service.config?.isPremiumForEver === true) && (service.service_config.find((f) => f.service_output_id == this.serviceTo.id)?.config.enabled == true || this.serviceToData.channel_id == 7) && (this.iframe == service.config["onlyIframe"] || !service.config["onlyIframe"]))
      for (const service of servicesFrom) {
        try {
          let defaultAutomapper = []
          let service_config = service.service_config.find((f) => f.service_output_id == this.serviceTo.id)
          if (service.config?.useInputDefaultAutomapper && service.inputs.find((f) => f.name == service.config.useInputDefaultAutomapper).value) {
            defaultAutomapper = service_config?.default_automapper[service.inputs.find((f) => f.name == service.config.useInputDefaultAutomapper).value] ?? []
          } else {
            defaultAutomapper = service_config?.default_automapper ?? []
          }
          let fieldsFrom = !service.fields || !service.fields.length ? await this.searchFieldsFrom(service) : service.fields
          let init = service.default_values.subtract_months != undefined ? this.convertDate(service.default_values.subtract_months) : null
          let serviceData = {
            ...service,
            fields: fieldsFrom,
            enable: true,
            openOptions: false,
            schedule_frequency: service.default_values.frequency ?? null,
            init,
            automapper: defaultAutomapper,
            default_automapper: defaultAutomapper,
            subtractDate: service.default_values.subtract_months ?? null,
            filter: "",
            is_premium: service.config.isPremiumForEver == true || (service.config?.canPremium === true && this.serviceToData.channel_id == 7) || (service_config.config && service_config.config?.isPremium),
            subTime_get_data: null
          }
          serviceData.inputsTo = !serviceData.is_premium || service?.config?.necesaryIntegrations ? serviceToData.inputs : []

          let inputs = JSON.parse(JSON.stringify(serviceData.inputs))
          if (inputs != null) {
            for (const [index, input] of inputs.entries()) {
              if (["select", "multiselect"].includes(input.type) && !input.options) {
                let functionOptions = input?.function ?? input.name
                let res = await axiosClient.post(`${process.env.VUE_APP_URL_API}/api/2.0/channel/${service.channel_id}/external/data/${functionOptions}`, {
                  service_id: service.id,
                  project_credential_id: this.projectCredentialFromId
                })
                inputs[index].options = res.data
                if(res.data.length == 1){
                  inputs[index].value = res.data[0][input.keyValue]
                }
                inputs[index].buttonReload = true
              }
              if (input.required == undefined) inputs[index].required = true
              if (input.value == undefined) inputs[index].value = null
              inputs[index].loading = false
            }
          } else {
            inputs = []
          }
          serviceData.inputs = JSON.parse(JSON.stringify(inputs))
          if ((service.config["isDefault"] && !this.integrations.length) || service.integration_id) this.servicesShow.push(JSON.parse(JSON.stringify(serviceData)))
          if (((!service.config["isDefault"] && !service.integration_id) || (!service.integration_id && this.integrations.length) || service.config["canDuplicate"]) && !this.servicesAgregate.find(f => f.id == serviceData.id)) this.servicesAgregate.push(serviceData)
        } catch (error) {
          this.servicesAgregate.push({ ...service, fields: [], enable: false, err: error })
          if ((service.config["isDefault"] && !this.integrations.length) || service.integration_id) this.servicesShow.push({
            ...service,
            fields: [],
            enable: false,
            err: error
          })
          if ((!service.config["isDefault"] && !service.integration_id) || (!service.integration_id && this.integrations.length) || service.config["canDuplicate"]) this.servicesAgregate.push({
            ...service,
            fields: [],
            enable: false,
            err: error
          })
        }
      }
    },

    async getFrequencies() {
      let res = await axiosClient.get(`${process.env.VUE_APP_URL_API}/api/2.0/utilities/schedule-frequency`)
      this.frequencies = res.data.data.map((frequency) => {
        frequency.text = this.$t(`integrations.automapper.${frequency.text}`)
        return frequency
      })
    },

    setInit(option, indexService) {
      this.servicesShow[indexService].init = this.convertDate(option.subtractDate)
    },

    convertDate(subtractNumber) {
      if (subtractNumber != 0) {
        return this.$moment().subtract(subtractNumber, "months").format("YYYY-MM-DD HH:mm:ss")
      } else {
        return this.$moment().format("YYYY-MM-DD HH:mm:ss")
      }
    },

    async showRelateFields(service, indexService) {
      this.modal.openRelatedFields = true
      this.modal.refreshFields = true
      this.modal.indexService = indexService
      this.modal.service = service
      this.modal.error = service.err ? true : false
      let verifyValuesNews = this.configSearchFieldsFrom[indexService] && Object.keys(this.configSearchFieldsFrom[indexService]).length ? service.inputs.filter((f) => f.required && f.reloadFields).some((f) => this.configSearchFieldsFrom[indexService][f.name] != f.value) : true
      let resetAutomapper = false
      if (service.inputs.filter((f) => f.required && f.reloadFields).length && (verifyValuesNews || service.err)) {
        resetAutomapper = true
        let config = {}
        service.inputs
          .filter((f) => f.required && f.reloadFields)
          .forEach((input) => {
            config[input.name] = input.value
          })
        this.configSearchFieldsFrom[indexService] = config
        let fields = await this.searchFieldsFrom(service, config)
        if(fields.length){
          this.servicesShow[indexService].fields = JSON.parse(JSON.stringify(fields))
          let indexServiceAggregate = this.servicesAgregate.map((f) => f.id).indexOf(service.id)
          if (indexServiceAggregate != -1) {
            this.servicesAgregate[indexServiceAggregate].fields = JSON.parse(JSON.stringify(fields))
          }
          this.servicesShow[indexService].err = null
          this.modal.error = false
        }else{
          this.modal.error = true
        }
      }

      let automappers = []
      if (service.automapper) {
        if (service.automapper.length > 0 && !resetAutomapper) {
          automappers = service.automapper
        } else if (service.default_automapper.length > 0 || Object.keys(service.default_automapper).length) {
          let serviceConfig = service.service_config.find((f) => f.service_output_id == this.serviceTo.id)
          automappers = service.config?.useInputDefaultAutomapper ? serviceConfig.default_automapper[service.inputs.find((f) => f.name == service.config.useInputDefaultAutomapper).value] ?? [] : serviceConfig.default_automapper
        }
      }

      automappers = automappers.length == 0 ? [{
        field_from: null,
        field_to: null,
        function: null,
        activeFunction: false
      }] : automappers
      this.modal.automappers = automappers.map(function(item) {
        if (!item.function) {
          item.function = null
          item.activeFunction = false
        } else {
          item.activeFunction = true
        }
        return item
      })
      let fieldsFrom = service.fields
      automappers
        .filter((f) => f.field_from)
        .forEach((value) => {
          if (!fieldsFrom.find((f) => f.label == value.field_from)) {
            fieldsFrom.push({ key: value.field_from, label: value.field_from })
          }
        })
      let fieldsTo = JSON.parse(JSON.stringify(this.serviceToData.fields))
      automappers
        .filter((f) => f.field_to)
        .forEach((value) => {
          if (!fieldsTo.find((f) => f.key == value.field_to)) {
            fieldsTo.push({ key: value.field_to, label: value.field_to })
          }
        })
      this.modal.fieldsFrom = fieldsFrom
      this.modal.fieldsTo = fieldsTo
      this.modal.name = service.name
      this.modal.refreshFields = false
    },

    async searchFieldsFrom(service, config = []) {
      let res = await axiosClient.post(`${process.env.VUE_APP_URL_API}/api/2.0/integrations/check-services/from`, {
        service_id: service.id,
        project_credential_id: this.projectCredentialFromId,
        config
      })

      return !res.data.errors ? res.data : []
    },

    async searchFieldsTo(service) {
      let res = await axiosClient.post(`${process.env.VUE_APP_URL_API}/api/2.0/integrations/check-services/to`, {
        service_id: service.id,
        project_credential_id: this.projectCredentialToId
      })

      return !res.data.errors ? res.data : []
    },

    async exampleFields() {
      this.modal.example.form.project_credential_id = this.projectCredentialFromId
      this.modal.example.form.service_id = this.modal.service.id
      this.modal.example.form.automapper = this.modal.automappers.map((automapper) => {
        if (!automapper.function) {
          return {
            field_from: automapper.field_from,
            field_to: automapper.field_to
          }
        }

        return {
          field_from: automapper.field_from,
          field_to: automapper.field_to,
          function: automapper.function
        }
      })
      this.modal.example.form.config = this.servicesShow
        .find((item, i) => i == this.modal.indexService)
        .inputs.reduce((acc, val) => {
          let valReturn = { ...acc }
          valReturn[val.name] = val.value
          return valReturn
        }, {})
      const response = await this.modal.example.form.post(ApiRoute.wizard.examples)

      this.modal.example.data = response.data
      this.modal.example.show = true
    },

    async refreshFields() {
      this.modal.refreshFields = true
      let fieldsFrom = []
      if (this.modal.service.inputs.filter((f) => f.required && f.reloadFields).length) {
        let config = {}
        this.modal.service.inputs
          .filter((f) => f.required && f.reloadFields)
          .forEach((input) => {
            config[input.name] = input.value
          })
        this.configSearchFieldsFrom[this.modal.indexService] = config
        try {
          fieldsFrom = await this.searchFieldsFrom(this.modal.service, config)
          this.servicesShow[this.modal.indexService].fields = fieldsFrom
          let indexServiceAggregate = this.servicesAgregate.map((f) => f.id).indexOf(this.modal.service.id)
          if (indexServiceAggregate != -1) {
            this.servicesAgregate[indexServiceAggregate].fields = fieldsFrom
          }
          this.servicesShow[this.modal.indexService].err = null
          this.modal.error = false
        } catch (error) {
          this.servicesShow[this.modal.indexService].err = error
          this.modal.error = true
          this.modal.refreshFields = false
          return
        }
      } else {
        fieldsFrom = await this.searchFieldsFrom(this.modal.service)
      }

      let fieldsTo = await this.searchFieldsTo(this.serviceTo)

      this.modal.fieldsFrom = fieldsFrom
      this.modal.fieldsTo = fieldsTo
      this.modal.refreshFields = false
    },

    removeFieldRelated(index) {
      this.modal.automappers.splice(index, 1)
    },

    saveRelateFields() {
      let automapper = JSON.parse(
        JSON.stringify(
          this.modal.automappers
            .filter((automapper) => {
              if (!automapper.field_from || !automapper.field_to) {
                return false
              }
              return true
            })
            .map((automapper) => {
              let item = { field_from: automapper.field_from, field_to: automapper.field_to }
              if (automapper.function != null && automapper.function != "") {
                item.function = automapper.function
              }
              return item
            })
        )
      )

      this.servicesShow[this.modal.indexService].automapper = automapper
      this.modal.openRelatedFields = false
      this.modal.automappers = [{ field_from: null, field_to: null, function: null, activeFunction: false }]
    },

    activeDesactiveFunction(indexAutompper) {
      this.modal.automappers[indexAutompper].activeFunction = !this.modal.automappers[indexAutompper].activeFunction
      if (!this.modal.automappers[indexAutompper].activeFunction) this.modal.automappers[indexAutompper].function = null
    },

    addService() {
      if(this.serviceSelected.config['canDuplicate'] && this.serviceSelected.integration_id){
        delete this.serviceSelected.integration_id
      }
      this.servicesShow.push(JSON.parse(JSON.stringify(this.serviceSelected)))
      if (!this.serviceSelected.config["canDuplicate"]) this.servicesAgregate = this.servicesAgregate.filter((f) => f.id != this.serviceSelected.id)
      this.serviceSelected = null
    },

    removeService(service, i) {
      this.$emit("deleteIntegration", this.servicesShow[i])
      this.servicesShow.splice(i, 1)
      if (!service.config["canDuplicate"]) this.servicesAgregate.push({ ...service })

      // this.$confirm.require({
      //   group: "confirm",
      //   message: this.$t("You won't be able to revert this!"),
      //   header: this.$t("Are you sure?"),
      //   icon: "pi pi-exclamation-triangle",
      //   acceptClass: "p-button-danger",
      //   acceptLabel: this.$t("yesDelete"),
      //   rejectLabel: this.$t("cancel"),
      //   accept: () => {
      //     this.$emit("deleteIntegration", this.servicesShow[i])
      //     this.servicesShow.splice(i, 1)
      //     if (!service.config["canDuplicate"]) this.servicesAgregate.push(service)
      //   }
      // })
    },

    showModalFilter(service, indexService) {
      this.modalFilter.indexService = indexService
      this.modalFilter.filter = service.filter
      this.modalFilter.show = true
    },

    saveFilter() {
      this.servicesShow[this.modalFilter.indexService].filter = this.modalFilter.filter
      this.modalFilter.show = false
    },

    sendDataFinish() {
      let error = false
      for (const service of this.servicesShow) {
        if (!service.is_premium && !service.automapper?.length) {
          this.$toast.error(`Debe ingresar el automapper para el servicio ${service.name}`)
          error = true
        }

        if (service.inputs) {
          service.inputs
            .filter((f) => f.required == true)
            .forEach((input) => {
              if (input.value === null || input.value === "") {
                this.$toast.error(`Debe completar el campo ${input.name} del servicio ${service.name}`)
                error = true
              }
            })
        }

        if (service.inputsTo) {
          service.inputsTo
            .filter((f) => f.required == true)
            .forEach((input) => {
              if (input.value == null || input.value == "") {
                this.$toast.error(`Debe completar el campo ${input.name} del servicio ${service.name}`)
                error = true
              }
            })
        }
      }

      if (error) {
        this.$emit("dataFinish", { error })
        return
      }

      let data = []

      this.servicesShow.forEach((s) => {
        let config_to = s.inputsTo.reduce((acc, val) => {
          let valReturn = { ...acc }
          valReturn[val.name] = val.value
          return valReturn
        }, {})
        let config_from = s.inputs.reduce((acc, val) => {
          let valReturn = { ...acc }
          valReturn[val.name] = val.value
          return valReturn
        }, {})
        if (s.filter != null && s.filter != "") {
          config_from.filter = JSON.parse(s.filter)
        }
        let serviceToId = null
        if (!s.is_premium) {
          serviceToId = this.serviceToData.id
        } else {
          serviceToId = s.config.service_output_id
        }
        let dataPush = {
          id: null,
          service_from_id: s.id,
          service_to_id: serviceToId,
          schedule_frequency: s.schedule_frequency,
          subTime_get_data: s.subTime_get_data,
          automapper: s.automapper,
          config_to: config_to,
          config_from: config_from,
          is_premium: s.is_premium,
          is_active:s.enable
        }
        if (s.integration_id) {
          dataPush.id = s.integration_id
        }else{
          dataPush.init = s.init
        }
        data.push(dataPush)
      })
      this.$emit("dataFinish", data)
    },

    getWebhookLink(service) {
      const integration = this.integrations.find((integration) => integration.id == service.integration_id)
      return integration?.config_from?.link || ""
    },

    copyWebhookLink(service) {
      service.copied = true
      this.$copyText(this.getWebhookLink(service))
      setTimeout(() => {
        service.copied = false
      }, 2000)
    },
    async reloadOptions(nameKey, inputIndex, indexService) {
      let channelId = nameKey == "inputs" ? this.servicesShow[indexService].channel_id : this.serviceTo.channel_id
      this.servicesShow[indexService][nameKey][inputIndex].loading = true
      let functionOptions = this.servicesShow[indexService][nameKey][inputIndex]?.function ?? this.servicesShow[indexService][nameKey][inputIndex].name
      let res = await axiosClient.post(`${process.env.VUE_APP_URL_API}/api/2.0/channel/${channelId}/external/data/${functionOptions}`, {
        project_credential_id: nameKey == "inputs" ? this.projectCredentialFromId : this.projectCredentialToId
      })
      this.servicesShow[indexService][nameKey][inputIndex].options = res.data
      this.servicesShow[indexService][nameKey][inputIndex].loading = false
    },
    injectDataIntegration(integrations) {
      integrations.forEach((integration, index) => {
        this.servicesShow[index].integration_id = integration.id
      })
    }
  }
}
</script>
